import xhrInstance from "services/config";
import objToParams from "utils/objToParams";

const getAdjustment = (params: any) => {
  return xhrInstance.get(objToParams(`/inventory/list-adjustment`, params));
};

const getInventory = (params: any) => {
  return xhrInstance.get(objToParams(`/inventory/list`, params));
};

const previewInventory = (params: Record<string, any>) => {
  return xhrInstance.get(objToParams("/inventory/preview", params));
};

const getAdjustmentDetail = (id: number | string) => {
  return xhrInstance.get(`/inventory/detail/${id}`);
};

const getAdjustmentHistory = (id: number | string) => {
  return xhrInstance.get(`/inventory/history/${id}`);
};

const addRemarkAdjustment = (params: any) => {
  return xhrInstance.put(`/inventory/submit-adjustment`, params);
};

const previewInventoryAdjustment = (params: any) => {
  return xhrInstance.get(objToParams(`/inventory/preview-adjustment`, params));
};

const PrintService = {
  getAdjustment,
  getInventory,
  getAdjustmentDetail,
  getAdjustmentHistory,
  addRemarkAdjustment,
  previewInventory,
  previewInventoryAdjustment,
};

export default PrintService;
