import { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch } from "stores";
import { Modal, Button, Form, Image, Table, Row, Input } from "antd";
import { toggleAdjustManyDialog } from "stores/inventory/adjust";
import { addRemark } from "stores/inventory/adjust/actions/adjust";
import { get, previewAdjustment } from "stores/inventory/adjust/actions/adjust";
import zeroSize from "utils/zeroSize";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

const DialogAdjustMany = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [form] = Form.useForm();

  const { isLoadingModal, dialogAdjustMany } = useSelector(
    (state: any) => state.adjustInv
  );

  const [dataTable, setDataTable]: any = useState([]);

  const getData = async () => {
    const data = await dispatch(
      previewAdjustment({
        page: 1,
      })
    ).unwrap();

    const parseData = data.data?.map((item: any) => ({ ...item, remarks: "" }));

    setDataTable(parseData);
  };

  useEffect(() => {
    if (dialogAdjustMany) {
      getData();
    }
  }, [dialogAdjustMany]);

  const handleCancel = () => {
    setDataTable([]);
    form.resetFields();
    dispatch(toggleAdjustManyDialog(false));
  };

  const handleRemarks = (event: any, index: number) => {
    const value = event.target.value;
    const changeData = dataTable.map((item: any, i: number) => {
      if (i === index) {
        return { ...item, remarks: value };
      }

      return item;
    });
    setDataTable(changeData);
  };

  const disableSubmit =
    dataTable.filter((item: any) => item.remarks !== "").length > 0
      ? false
      : true;

  const handleSubmit = async () => {
    const req = {
      data_assets: dataTable
        .filter((item: any) => item.remarks !== "")
        ?.map((r: any) => ({
          asset_id: r.asset_id,
          id: r.id,
          remarks: r.remarks,
        })),
    };

    await dispatch(
      addRemark({
        body: req,
      })
    ).unwrap();
    await dispatch(get({ page: 1, limit: 10 })).unwrap();
    handleCancel();
  };

  const columns: any = [
    // {
    //   title: "No",
    //   dataIndex: "no",
    //   key: "no",
    //   width: 50,
    //   align: "center",
    // },
    {
      title: "Customer",
      dataIndex: "customer",
      key: "customer",
      width: 120,
    },
    {
      title: "Code Skid",
      dataIndex: "code_skid",
      key: "code_skid",
      width: 150,
    },
    {
      title: "Size SKID",
      children: [
        {
          title: "T",
          key: "skidT",
          width: 80,
          align: "center",
          render: (dataRecord: any) => zeroSize(dataRecord?.thick),
        },
        {
          title: "W",
          key: "skidW",
          width: 80,
          align: "center",
          render: (dataRecord: any) => zeroSize(dataRecord?.width),
        },
        {
          title: "L1",
          key: "skidL1",
          width: 80,
          align: "center",
          render: (dataRecord: any) => zeroSize(dataRecord?.length1),
        },
        {
          title: "L2",
          key: "skidL2",
          width: 80,
          align: "center",
          render: (dataRecord: any) => zeroSize(dataRecord?.length2),
        },
      ],
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      width: 50,
      align: "center",
      render: (status: string) => (status ? status.toUpperCase() : "-"),
    },
    {
      title: "Last Scan",
      key: "last",
      // dataIndex: "scanned",
      width: 100,

      render: (text: any) =>
        text.last_scan
          ? text.last_scan === "-"
            ? "-"
            : dayjs.utc(text.last_scan).format("DD/MM/YYYY HH:mm")
          : "No",
    },
    {
      title: "Remarks",
      key: "remarks",
      dataIndex: "remarks",
      width: 200,
      align: "center",
      render: (text: string, record: any, index: any) => (
        <Input
          onChange={(event) => handleRemarks(event, index)}
          placeholder={"add remarks"}
        />
      ),
    },
  ];

  return (
    <>
      {/* dialog element */}
      <Modal
        forceRender
        open={dialogAdjustMany}
        title="List for Remark"
        onCancel={() => handleCancel()}
        width={1000}
        footer={[
          <Button onClick={() => handleCancel()} key="back">
            Cancel
          </Button>,
          <Button
            type="primary"
            disabled={disableSubmit}
            onClick={() => handleSubmit()}
            key="back"
          >
            Submit
          </Button>,
        ]}
      >
        <Table
          id="my-table-id"
          bordered
          rowKey={(record: any) => record.id}
          loading={isLoadingModal}
          columns={columns}
          dataSource={dataTable}
          pagination={false}
        />
      </Modal>
    </>
  );
};

export default DialogAdjustMany;
