import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch } from "stores";
import { Modal, Button, Input } from "antd";
import { toggleActionDialog } from "stores/inventory/adjust";
import { get } from "stores/inventory/adjust/actions/adjust";
import { addRemark } from "stores/inventory/adjust/actions/adjust";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

const ModalRemark = ({ dataRecord }: any) => {
  const dispatch = useDispatch<AppDispatch>();
  const { dialogAction, isLoading } = useSelector(
    (state: any) => state.adjustInv
  );

  const [remarks, setRemarks] = useState("");

  const handleCancel = () => {
    setRemarks("");
    dispatch(toggleActionDialog(false));
  };

  const handleSubmit = async () => {
    const req = {
      data_assets: [
        {
          asset_id: dataRecord.asset_id,
          id: dataRecord.id,
          remarks,
        },
      ],
    };

    await dispatch(
      addRemark({
        body: req,
      })
    ).unwrap();
    setRemarks("");
    await dispatch(get({ page: 1, limit: 10 })).unwrap();
  };

  return (
    <>
      {/* dialog element */}
      <Modal
        forceRender
        open={dialogAction}
        title="Adjustment Action"
        onCancel={() => handleCancel()}
        footer={[
          <Button
            loading={isLoading}
            onClick={handleSubmit}
            key="submit"
            disabled={remarks.length === 0}
            type="primary"
          >
            Submit
          </Button>,
          <Button onClick={() => handleCancel()} key="back">
            Cancel
          </Button>,
        ]}
      >
        <div className="mt-5">
          <div className="mb-1">Remark</div>
          <Input value={remarks} onChange={(e) => setRemarks(e.target.value)} />
        </div>
      </Modal>
    </>
  );
};

export default ModalRemark;
